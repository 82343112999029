/* ----------------------------------

Template Name: MediHub - Medical & Health Template
Author: validtheme
Description:
Version: 1.3

Main Font    : Poppins
Main Color   : #0cb8b6

-------------------------------------

Table of contents

    01. Template default css
    02. Navbar
        - Navbar Default
        - Navbar Sticky
        - Navbar Transparent
        - Nav With Topbar
    03. Banner
    04. Features
    05. About Area
    06. Services
        - Carousel
        - Grid
        - Static
    07. Portfolio
        - Grid Colum
        - Masonary Colum
    08. Doctors
        - Carousel
        - Grid
        - Static
    09. Department Area
    10. Fun Factor
    11. Blog
        - Standard
        - Left Sidebar
        - Right Sidebar
    12. Concat Area
    13. Subscribe Form
    14. Error 404
    15. Footer
    16. PHP Contact Form
    17. Others

*/


/*
** General Styles for HTML tags
*/

* {
    padding: 0;
    margin: 0;
}

html {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: visible;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    overflow-x: hidden;
    background-color: #ffffff;
    color: #666666;
}

html, body, .wrapper {
    height: 100%;
}

h1, h2, h3, h4, h5, h6 {
    color: #232323;
    font-weight: normal;
    line-height: 1.2;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0;
    margin: 0;
}

h1, h2, h3, h4 {
    margin-bottom: 15px;
}

h5, h5 {
    margin-bottom: 10px;
}

h6 {
    font-size: 14px;
}


img {
    border: none;
    outline: none;
    max-width: 100%;
}

label {
    display: inline-block;
    font-weight: normal;
    margin-bottom: 5px;
    max-width: 100%;
}

a, .btn, button {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    outline: medium none;
}

a img, iframe {
    border: none;
}

p {
    color: #666666;
    line-height: 26px;
    margin: 0 0 15px;
    text-transform: none;
    font-weight: 400;
}

hr {
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid #eee;
}

pre {
    display: block;
    margin: 0 0 30px;
    padding: 9.5px;
    word-wrap: break-word;
    word-break: break-all;
    color: #333;
    border: 1px solid #ededed;
    border-radius: inherit;
    background-color: #f9f9f9;
    font-size: 13px;
    line-height: 1.42857143;
}

input:focus, textarea:focus,
select:focus {
    outline: none;
    box-shadow: inherit;
}

ul {
    margin: 0;
    list-style-type: none;
}

a, a:active, a:focus, a:hover {
    outline: none;
    text-decoration: none;
    color: #232323;
}

a:hover {
    color: #232323;
}

a {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}

b, strong {
    font-weight: 900;
}

.btn.active, .btn:active {
    background-image: inherit !important;
}

.btn.focus,
.btn:active:focus,
.btn:active:hover,
.btn:focus,
.btn:hover {
    outline: 0;
}

.btn.circle {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

.btn.active, .btn:active {
    outline: 0;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
}

input {
    border: 1px solid #e7e7e7;
    border-radius: inherit;
    box-shadow: inherit;
    min-height: 50px;
}

/* Default CSS */
.container-half {
    margin: 0 auto;
    width: 60%;
    padding: 0 15px;
}

.bg-cover {
    background-position: center center;
    background-size: cover;
}

.bg-contain {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.bg-fixed {
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
}

.bg-gray {
    background-color: #f7f7f7;
}

.bg-theme-small {
    background-color: #edf5ff;
}

.bg-light {
    background-color: #ffffff;
}

.bg-theme {
    background-color: #0cb8b6;
}

.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6,
.text-light p,
.text-light a {
    color: #ffffff;
}

.shadow {
    position: relative;
    z-index: 1;
}

.shadow.dark::after {
    background: #000000 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.shadow.dark-hard::after {
    background: #000000 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.shadow.light::after {
    background: #ffffff none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.3;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.shadow.theme::after {
    background: #0cb8b6 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.shadow.theme-hard::after {
    background: #0cb8b6 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.7;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.default-padding {
    padding-top: 120px;
    padding-bottom: 120px;
}

.default-padding-top {
    padding-top: 120px;
}

.default-padding-bottom {
    padding-bottom: 120px;
}

.default-padding.bottom-less {
    padding-top: 120px;
    padding-bottom: 90px;
}

.default-padding.bottom-30 {
    padding-top: 120px;
    padding-bottom: 30px;
}

.default-padding.bottom-20 {
    padding-top: 120px;
    padding-bottom: 20px;
}

.padding-xl {
    padding-top: 180px;
    padding-bottom: 180px;
}

.carousel-shadow {
    padding-bottom: 105px;
}

.btn {
    display: inline-block;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 25px;
    text-transform: uppercase;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: inherit;
    overflow: hidden;
}

.btn-md {
    padding: 10px 40px 10px;
}

.btn-sm {
    padding: 8px 35px;
    font-size: 12px;
}

.btn.btn-sm-pro {
    font-size: 10px;
    font-weight: 600;
    margin-top: 5px;
    padding: 4px 35px;
    display: inline-block;
}

.btn-border-light {
    border: 2px solid #ffffff;
}

.btn.btn-sm-pro.btn-border-light:hover,
.btn.btn-sm-pro.btn-border-light:focus {
    background-color: #ffffff;
    color: #232323;
    border: 2px solid #ffffff;
}

.btn-dark {
    background-color: #232323;
    color: #ffffff;
    border: 2px solid #232323;
}

.btn-dark.border {
    background-color: transparent;
    color: #232323;
    border: 2px solid #232323;
}

.btn-dark.border:hover {
    background-color: #232323;
    color: #ffffff !important;
    border: 2px solid #232323;
}

.btn.btn-light {
    background: #ffffff none repeat scroll 0 0;
    border: 2px solid #ffffff;
    color: #232323;
}

.btn.btn-light:hover,
.btn.btn-light:focus {
    background: transparent none repeat scroll 0 0;
    border: 2px solid #ffffff;
    color: #ffffff;
}

.btn.btn-light.effect:hover,
.btn.btn-light.effect:focus {
    background-color: #0cb8b6;
    color: #ffffff !important;
    border: 2px solid #0cb8b6;
}

.btn.btn-light.border {
    background: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
}

.btn.btn-light.border:hover {
    background: #ffffff none repeat scroll 0 0 !important;
    border: 2px solid #ffffff !important;
    color: #232323 !important;
}

.btn-dark:hover,
.btn-dark:focus {
    color: #232323 !important;
    background: transparent;
}

.bg-dark {
    background: #1d2024 none repeat scroll 0 0;
}

.bg-gradient {
    background: linear-gradient(to bottom, #ffffff 2%, #0cb8b6 100%);
}

.gradient-bg {
    background: linear-gradient(40deg, rgba(12, 184, 182, 1) 90%, rgba(31, 246, 244, 1) 100%);
}

.btn-theme {
    background-color: #0cb8b6;
    color: #ffffff !important;
    border: 2px solid #0cb8b6;
}

.btn-theme.border {
    background-color: transparent;
    color: #232323 !important;
    border: 2px solid #0cb8b6;
}

.btn-theme.border:hover {
    background-color: #0cb8b6;
    color: #ffffff !important;
    border: 2px solid #0cb8b6;
}

.btn-theme.effect:hover,
.btn-theme.effect:focus {
    background: #0cb8b6 none repeat scroll 0 0;
    border: 2px solid #0cb8b6;
    color: #ffffff;
}

.btn.btn-sm-lm {
    font-size: 12px;
    padding: 4px 35px;
}

.bg-theme a.btn-theme.btn:hover,
.bg-theme a.btn-theme.btn:focus {
    border: 2px solid #ffffff;
    color: #ffffff;
}

.bg-fixed {
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
}

.bg-cover {
    background-position: center center;
    background-size: cover;
}

.container-full {
    padding: 0 15px;
    width: 100%;
}

.oh {
    overflow: hidden;
}

.less-margin {
    margin: 0;
}


@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
        width: 1200px;
    }
}

.container-medium {
    margin: auto;
    max-width: 85%;
}

@media (min-width: 576px) {
    .container-medium {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container-medium {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container-medium {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container-medium {
        max-width: 1200px;
        width: 1200px;
    }
}

@media screen and (min-width: 1200px) {
    .item-flex {
        display: flex;
    }

    .item-flex.center {
        align-items: center;
    }

}

header {
    position: relative;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a {
    position: relative;
    z-index: 1;
    margin-left: 3px;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background .attr-nav ul li a {
    border: medium none;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a::after {
    background: #ffffff none repeat scroll 0 0;
    bottom: -2px;
    content: "";
    height: 3px;
    left: 0;
    position: absolute;
    width: 0;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a.active::after,
header nav.navbar.border.bootsnav.navbar-fixed.no-background ul li a:hover::after {
    width: 100%;
}

header nav.navbar.border.bootsnav.navbar-fixed.no-background .attr-nav ul li a.active::after,
header nav.navbar.border.bootsnav.navbar-fixed.no-background .attr-nav ul li a:hover::after {
    width: 0;
}

.attr-nav .social.right li {
    margin-left: 20px;
}

.attr-nav .social li a {
    font-size: 16px;
}

/* Navbar */
nav.bootsnav.navbar-default.info-topbar .navbar-header {
    display: none;
}

nav.bootsnav.navbar-default.info-topbar ul li a {
    margin-right: 30px;
    padding: 35px 0;
}

nav.bootsnav.navbar-default.info-topbar.active-full ul li a {
    margin-right: 0;
    padding: 35px 20px;
}

.attr-nav > a.btn-theme.effect:hover,
.attr-nav > a.btn-theme.effect,
.attr-nav > a.btn-theme.effect:focus {
    background: #0cb8b6 none repeat scroll 0 0;
    border: 2px solid #0cb8b6;
    color: #ffffff !important;
}

nav.bootsnav.navbar-default.info-topbar.sticked ul li a {
    margin-right: 30px;
    padding: 35px 0;
}

.attr-nav.social li {
    display: inline-block;
    padding: 25px 0 !important;
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
}

.attr-nav.social li a {
    border: 1px solid #f4f4f4;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color: #0cb8b6;
    display: inline-block;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    margin-left: 10px;
    padding: 0 !important;
    text-align: center;
    width: 40px;
}

nav.navbar.bootsnav.sticked .attr-nav.social li {
    display: inline-block;
    padding: 20px 0 !important;
}

.site-heading h2 {
    display: inline-block;
    font-weight: 700;
    padding-bottom: 20px;
    position: relative;
    text-transform: uppercase;
}

.site-heading h4 {
    text-transform: uppercase;
}

.site-heading.clean h2 {
    padding-bottom: 0;
}

.site-heading.clean h2 span {
    color: #0cb8b6;
}

.site-heading h2 span {
    color: #0cb8b6;
}

.site-heading.barber h2 span {
    color: #bc9355;
}

.site-heading p {
    margin: 0;
}

.site-heading h2::after {
    background: #0cb8b6 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 50%;
    margin-left: -25px;
    position: absolute;
    width: 40px;
}

.site-heading h2::before {
    background: #0cb8b6 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 50%;
    margin-left: 20px;
    position: absolute;
    width: 5px;
}

.bg-theme .site-heading h2::before,
.shadow .site-heading h2::before,
.half-bg .site-heading h2::before {
    background: #ffffff none repeat scroll 0 0;
}

.site-heading h2 span {
    color: #0cb8b6;
}

.site-heading {
    margin-bottom: 60px;
    overflow: hidden;
    margin-top: -5px;
}

.site-heading.single {
    margin-bottom: 35px;
}

.carousel-shadow .owl-stage-outer {
    margin: -15px -15px 0;
    padding: 15px;
}

/* ==============================================================
     # Bradcrumb
=================================================================== */
.breadcrumb-area {
    padding: 150px 0;
}

.breadcrumb-area .breadcrumb {
    background: transparent none repeat scroll 0 0;
    display: inline-block;
    margin: 0;
    padding: 15px;
    position: relative;
    z-index: 1;
}

.breadcrumb-area .breadcrumb::after {
    background: #232323 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.7;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.breadcrumb-area h1 {
    display: block;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 40px;
    padding-top: 6px;
    margin: 0;
}

.breadcrumb > li + li::before {
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    padding: 0 5px;
    color: #ffffff;
    display: none;
}

.breadcrumb-area .breadcrumb li i {
    margin-right: 5px;
}

.breadcrumb-area .breadcrumb li {
    padding: 0 15px;
    position: relative;
    z-index: 1;
}

.breadcrumb-area .breadcrumb li::after {
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    left: -5px;
    font-weight: 600;
    position: absolute;
    top: 1px;
}

.breadcrumb-area .breadcrumb li:first-child::after {
    display: none;
}

.breadcrumb-area .breadcrumb a,
.breadcrumb-area .breadcrumb li {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    color: #ffffff;
}

.breadcrumb-area .breadcrumb li.active {
    color: #0cb8b6;
    text-decoration: underline;
}

/* ==============================================================
     # Topbar Area
=================================================================== */
.top-bar-area.inc-border {
    border-bottom: 1px solid #e7e7e7;
}

.top-bar-area {
    overflow: hidden;
}

.top-bar-area li {
    display: inline-block;
}

.top-bar-area li .icon, .top-bar-area li .info {
    display: table-cell;
    vertical-align: middle;
}

.top-bar-area li .info span {
    display: block;
    font-family: 'Poppins', sans-serif;
    color: #232323;
    text-transform: uppercase;
    line-height: 20px;
    font-weight: 600;
}

.top-bar-area li .icon i {
    font-size: 30px;
    color: #0cb8b6;
    margin-right: 15px;
}

.top-bar-area li:last-child {
    margin-right: 0;
    padding-right: 0;
}

.top-bar-area li:last-child::after {
    display: none;
}

.top-bar-area .info li {
    float: left;
    margin-right: 20px;
    position: relative;
    z-index: 1;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.top-bar-area.inline .info li {
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.top-bar-area.bg-dark.text-light .info li {
    color: #ffffff;
}

.top-bar-area.bg-dark.text-light .info li::after {
    display: none;
}

.top-bar-area .info li::after {
    position: absolute;
    right: 0;
    top: -10px;
    content: "";
    height: 100px;
    width: 1px;
    transform: rotate(20deg);
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    background: #e7e7e7;
}

.top-bar-area.double-info .social {
    padding: 25px 15px;
}

.top-bar-area.bg-theme .info li::after,
.top-bar-area.bg-dark .info li::after {
    background: rgba(255, 255, 255, 0.2);
}

.top-bar-area .social {
    padding: 15px 0;
    z-index: 1;
}

.top-bar-area.bg-theme li .icon i {
    color: #ffffff;
}

.top-bar-area.bg-theme li .info span {
    color: #ffffff;
}

.top-bar-area.bg-theme .info li {
    color: #ffffff;
}

.top-bar-area.bg-theme {
    position: relative;
    z-index: 1;
}

.top-bar-area.bg-theme::after {
    position: absolute;
    right: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 50%;
}

.top-bar-area.inline .info li i {
    margin-right: 10px;
    color: #0cb8b6;
    font-size: 20px;
}

.top-bar-area.inline.bg-theme .info li i,
.top-bar-area.inline.bg-dark .info li i {
    color: #ffffff;
}

.top-bar-area .social.text-right ul li {
    margin-right: 0;
    margin-left: 30px;
}

.top-bar-area .social ul li a {
    display: inline-block;
    font-size: 16px;
    color: #666666;
}

.top-bar-area.bg-theme .social ul li a {
    color: #ffffff;
}

.top-bar-area .bar-btn a {
    text-transform: uppercase;
    font-weight: 600;
    padding: 15px 35px;
    color: #ffffff;
    background: #0cb8b6;
    display: inline-block;
    letter-spacing: 1px;
}

/* Side BG */

.side-bg {
    height: 100%;
    position: absolute;
    left: -30px;
    top: 0;
    width: 30%;
}

.side-bg img {
    left: 0;
    position: absolute;
    top: 50%;
    opacity: 0.1;
    transform: translate(0, -50%);
}


/* ==============================================================
     # Banner
=================================================================== */
body,
.banner-area,
.banner-area div {
    height: 100%;
}

.banner-area.auto-height,
.banner-area.auto-height div {
    height: auto;
    min-height: 100%;
}

.banner-area .box-cell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}

.banner-area .box-table {
    display: table;
    width: 100%;
}

.banner-area .box-cell,
.banner-area .box-cell div {
    height: auto;
}

.banner-area .transparent-nav .content {
    padding-top: 80px;
}

.banner-area {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.banner-area.left-shadow::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    z-index: -1;
    height: 100%;
    width: 80%;
    background: #0cb8b6;
}

.banner-area .content {
    position: relative;
    z-index: 9;
}

.banner-area.content-double .double-items {
    align-items: center;
    align-self: center;
    display: flex;
    justify-content: center;
    vertical-align: middle;
}

/* Animation delays */
.banner-area .item h1:first-child,
.banner-area .item h2:first-child,
.banner-area .item h3:first-child,
.banner-area .item h4:first-child,
.banner-area .item h5:first-child,
.banner-area .item h6:first-child {
    animation-delay: .5s;
}

.banner-area .item h1:nth-child(2),
.banner-area .item h2:nth-child(2),
.banner-area .item h3:nth-child(2),
.banner-area .item h4:nth-child(2),
.banner-area .item h5:nth-child(2),
.banner-area .item h6:nth-child(2) {
    animation-delay: .7s;
}

.carousel-caption h1:nth-child(3),
.carousel-caption h2:nth-child(3),
.carousel-caption h3:nth-child(3),
.carousel-caption h4:nth-child(3),
.carousel-caption h5:nth-child(3),
.carousel-caption h6:nth-child(3) {
    animation-delay: .9s;
}

.banner-area .item p {
    animation-delay: .7s;
}

.banner-area .item ul {
    animation-delay: .6s;
}

.banner-area .item a,
.banner-area .item button {
    animation-delay: .9s;
}

/* Carousel Fade Effect */
.carousel-fade .carousel-inner .item {
    -webkit-transition-property: opacity;
    transition-property: opacity;
}

.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    opacity: 0;
}

.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
    opacity: 1;
}

.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
}

.carousel-fade .carousel-control {
    z-index: 2;
}

/* Slider Zoom Effect */
@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(1, 1);
    }

    to {
        -webkit-transform: scale(1.2, 1.2);
    }
}

@-moz-keyframes zoom {
    from {
        -moz-transform: scale(1, 1);
    }

    to {
        -moz-transform: scale(1.2, 1.2);
    }
}

@-o-keyframes zoom {
    from {
        -o-transform: scale(1, 1);
    }

    to {
        -o-transform: scale(1.2, 1.2);
    }
}

@keyframes zoom {
    from {
        transform: scale(1, 1);
    }

    to {
        transform: scale(1.2, 1.2);
    }
}

.carousel-inner .item > .slider-thumb {
    -webkit-animation: zoom 20s;
    animation: zoom 20s;
}

.banner-area .carousel-zoom .slider-thumb {
    height: 100%;
    position: absolute;
    width: 100%;
}

#particles-js,
#ripple {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.banner-area .carousel-control {
    background: transparent none repeat scroll 0 0;
    font-size: 40px;
    height: 50px;
    line-height: 50px;
    margin-top: -25px;
    opacity: 1;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 50px;
    z-index: 1;
}

.banner-area .carousel-control.shadow {
    background: transparent none repeat scroll 0 0;
    color: #ffffff;
    font-size: 20px;
    height: 50px;
    line-height: 50px;
    margin-top: -25px;
    opacity: 1;
    padding: 0;
    position: absolute;
    text-shadow: inherit;
    top: 50%;
    width: 50px;
    z-index: 1;
}

.banner-area .carousel-control.shadow::after {
    background: #1e2726 none repeat scroll 0 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.banner-area .carousel-control.left {
    left: -50px;
}

.banner-area:hover .carousel-control.left {
    left: 0;
}

.banner-area:hover .carousel-control.shadow.left {
    left: 20px;
}

.banner-area .carousel-control.right {
    right: -50px;
}

.banner-area:hover .carousel-control.right {
    right: 0;
}

.banner-area:hover .carousel-control.shadow.right {
    right: 20px;
}

/* Content */

.banner-area .double-items {
    display: flex;
    align-items: center;
}

.banner-area .content {
    overflow: hidden;
}

.banner-area .content p {
    font-size: 16px;
    line-height: 30px;
}

.banner-area.text-dark .content p {
    color: #232323;
}

.banner-area .content h1 {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 25px;
    line-height: 1.2;
}

.banner-area .double-content .content h1 {
    font-size: 50px;
}

.banner-area.text-small .content h1 {
    font-size: 60px;
}

.banner-area.heading-exchange .content h1 {
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.1;
}

.banner-area .content h4 {
    font-size: 24px;
}

.banner-area.heading-exchange .content h2,
.banner-area.heading-exchange .content h3,
.banner-area.heading-exchange .content h4 {
    font-weight: 400;
    margin-bottom: 25px;
    position: relative;
    z-index: 1;
}

.banner-area.heading-exchange .double-content .content h2,
.banner-area.heading-exchange .double-content .content h3,
.banner-area.heading-exchange .double-content .content h4 {
    margin-bottom: 25px;
    position: relative;
    z-index: 1;
    text-transform: capitalize;
    color: #666666;
}

.banner-area .content span {
    color: #0cb8b6;
}

.banner-area .content a {
    margin-top: 15px;
    margin-right: 5px;
}

.banner-area.content-box .content {
    position: relative;
    z-index: 1;
    padding: 30px;
}

.banner-area.content-box .content::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 100%;
    background: #000000;
    z-index: -1;
    opacity: 0.6;
}

.banner-area.content-box .content h1 {
    font-size: 60px;
}

.banner-area .appoinment-box .col-md-12 {
    float: left;
    width: 100%;
}

/* Banner Latest */

.banner-area .double-content .container > .row {
    display: flex;
    align-items: center;
}

.banner-area .double-content .thumb {
    padding-right: 35px;
}

.banner-area.top-pad-50p .content.double-items {
    padding-top: 50px;
}


.banner-area.border-shape {
    position: relative;
    z-index: 1;
}

.banner-area.border-shape:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 0;
    border-right: 100vw solid #ffffff;
    border-top: 250px solid transparent;
    width: 0;
    z-index: -1;
}

.banner-area.bg-gradient .content .thumb {
    padding-left: 35px;
}

.banner-area.bg-gradient .content .thumb img {
    max-width: 130%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    background: #ffffff;
}

.banner-area.bg-gradient .content {
    overflow: inherit;
}

.banner-area.bg-gradient .content p {
    color: #232323;
    line-height: 34px;
}

.banner-area.standard .info {
    padding: 200px 15px;
}

.banner-area.standard .thumb {
    position: relative;
}

.banner-area.standard .thumb img {
    position: relative;
    bottom: -120px;
}

.banner-area.standard .content {
    overflow: inherit;
}

.banner-area.standard .content h1 {
    font-size: 60px;
}

.banner-area.standard .content a {
    margin-top: 20px;
}

.banner-area.standard .content p {
    margin-bottom: 0;
}

.banner-area.standard .content a.video-play-button {
    margin-left: 10px;
    margin-bottom: 35px;
}

.banner-area.standard .fixed-shape {
    position: absolute;
    right: 0;
    top: 0;
    height: 80%;
    width: 60%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
}

/* ==============================================================
     # Video Button Play
=================================================================== */
.video-play-button {
    color: #0cb8b6;
    font-size: 30px;
    left: 50%;
    padding-left: 7px;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    z-index: 1;
}

.video-play-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #ffffff repeat scroll 0 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-animation: pulse-border 1500ms ease-out infinite;
    animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #ffffff repeat scroll 0 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    transition: all 200ms;
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -ms-transition: all 200ms;
    -o-transition: all 200ms;
}

@-webkit-keyframes pulse-border {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

@keyframes pulse-border {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

.video-play-button i {
    display: block;
    position: relative;
    z-index: 3;
    color: #0cb8b6;
}

.video-play-button.relative {
    position: relative;
    height: 80px;
    width: 80px;
    display: inline-block;
    left: 0;
    top: 0;
    line-height: 80px;
    text-align: center;
    transform: inherit;
}

.video-play-button.relative.theme::after,
.video-play-button.relative.theme::before {
    background: #0cb8b6;
}

.video-play-button.relative.theme i {
    color: #ffffff;
}

/* ==============================================================
     # Top Entry
=================================================================== */

.top-entry-area.text-center {
    border-top: 3px solid #0cb8b6;
}

.top-entry-area.border-less {
    border: none;
}

.top-entry-area .item-box {
    overflow: hidden;
}

.top-entry-area .item {
    padding: 80px 30px;
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.top-entry-area ul {
    text-align: left;
}

.top-entry-area ul li {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.top-entry-area ul li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.top-entry-area ul li:last-child {
    border: none;
    margin: 0;
    padding: 0;
}

.top-entry-area h4 {
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    padding-bottom: 20px;
    z-index: 1;
    display: inline-block;
    margin-bottom: 20px;
}

.top-entry-area h4::after {
    position: absolute;
    left: 50%;
    bottom: 0;
    content: "";
    height: 2px;
    width: 30px;
    background: #0cb8b6;
    margin-left: -15px;
}

.top-entry-area h2 {
    margin: 0;
}

.top-entry-area .item-box .single-item:nth-child(2n) h4::after {
    background: #ffffff;
}

.top-entry-area p {
    margin: 0;
}

.top-entry-area .item a {
    text-transform: uppercase;
    display: inline-block;
    margin-top: 25px;
    font-weight: 600;
    color: #666666;
}

.top-entry-area .item > i {
    font-size: 60px;
    margin-bottom: 25px;
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    color: #0cb8b6;
    display: block;
}

.top-entry-area .item-box .single-item {
    position: relative;
    z-index: 1;
}

.top-entry-area .item-box .single-item:nth-child(2n) {
    background: #0cb8b6;
    color: #ffffff;
}

.top-entry-area.border-less .item-box .single-item:nth-child(2n) {
    border-radius: 5px;
}

.top-entry-area .item-box .single-item:nth-child(2n)::after {
    height: 150%;
    width: 100%;
    left: 0;
    top: -25%;
    content: "";
    position: absolute;
    background: #0cb8b6;
    z-index: -1;
}

.top-entry-area.border-less .item-box .single-item:nth-child(2n)::after {
    border-radius: 5px;
    height: 100%;
    top: 0;
}

.top-entry-area .item-box .single-item:nth-child(2n) i,
.top-entry-area .item-box .single-item:nth-child(2n) h2,
.top-entry-area .item-box .single-item:nth-child(2n) h4,
.top-entry-area .item-box .single-item:nth-child(2n) p {
    color: #ffffff;
}


/* ==============================================================
     # About
=================================================================== */

.about-area .info {
    padding-right: 35px;
}

.about-area .inc-video .thumb {
    position: relative;
    z-index: 1;
}

.about-area .inc-video .thumb:after {
    position: absolute;
    left: 5%;
    top: 5%;
    content: "";
    height: 90%;
    width: 90%;
    -moz-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
    -o-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.about-area .features-items {
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: inline-block;
    width: 100%;
    padding: 30px;
    margin-top: 15px;
}

.about-area .tab-content .info a {
    margin-top: 10px;
}

.about-area .features-items i {
    font-size: 50px;
    color: #0cb8b6;
    margin-bottom: 25px;
}

.about-area .features-items h4 {
    text-transform: capitalize;
    font-weight: 600;
}

.about-area .features-items p {
    margin: 0;
}

.about-area .features-items .single-item {
    border-right: 1px solid #e7e7e7;
}

.about-area .features-items .single-item:last-child {
    border: none;
}

.features-items.owl-carousel.features-carousel .owl-dots {
    margin: 0;
}

.features-items.owl-carousel.features-carousel .owl-dots .owl-dot span {
    border: 3px solid #e7e7e7;
    height: 20px;
    width: 20px;
}

.features-items.owl-carousel.features-carousel .owl-dots .owl-dot.active span {
    background: #0cb8b6;
}

.about-area .tab-content .info {
    padding-right: 0;
}

/* Table */
.about-area .em-services-items table {
    border: 1px solid #e5e5e5;
    margin: 0;
}

.about-area .em-services-items table thead {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    background-color: #f7f7f7;
}

.about-area .em-services-items table th {
    border-bottom: none;
}

.about-area .em-services-items table th,
.about-area .em-services-items table td {
    text-align: left;
    padding: 18px 20px 18px 40px;
}

.emergency-services h2 {
    text-transform: capitalize;
    font-weight: 600;
}

.em-services-items {
    margin-top: 30px;
}

.about-area .em-services-items table td i {
    font-weight: 500;
    margin-right: 5px;
    color: #0cb8b6;
}

.half-bg {
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.half-bg::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    background: #0cb8b6;
    z-index: -1;
    height: 100%;
    width: 50%;
}

.about-area.half-bg .emergency-services {
    padding-left: 50px;
}

.about-area.half-bg .info {
    padding-right: 50px;
}

.about-area .bottom {
    border: 1px solid #e7e7e7;
    display: flex;
    padding: 30px;
    margin-top: 30px;
}

.about-area .info h4 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 15px;
}

.about-area .info > h4 {
    text-transform: uppercase;
    font-weight: 600;
    color: #0cb8b6;
    margin-bottom: 25px;
}

.about-area .info h2 {
    font-weight: 600;
    margin-bottom: 25px;
}

.about-area .info > a {
    margin-top: 15px;
}

.about-area .bottom .content {
    padding-left: 30px;
}

.about-area .bottom .content p {
    margin: 0;
}

.video-play-button.relative i {
    line-height: 80px;
}

.about-area .services-area .equal-height {
    margin-bottom: 0;
    margin-top: 30px;
}

.about-area .services-area .equal-height:first-child,
.about-area .services-area .equal-height:nth-child(2) {
    margin-top: 0;
}

.about-area .services-area .equal-height .item h4 {
    margin-bottom: 0;
}

.about-area .services-area.inc-icon .item {
    border-top: none;
}

.about-area .services-area.inc-icon .item i::after {
    left: -13px;
    top: -13px;
    height: 126px;
    width: 126px;
    opacity: 1;
    border: 2px solid #e7e7e7;
}

.about-area .services-area.inc-icon .item i {
    background: #ffffff;
    color: #0cb8b6;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
}

/* ==============================================================
     # Features
=================================================================== */

.features-area .features-items {
    padding-left: 35px;
}

.features-area .features-items i {
    text-align: center;
    font-size: 60px;
    margin-bottom: 25px;
    color: #232323;
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    min-width: 65px;
}

.features-area .features-items li {
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 30px;
    padding-bottom: 30px;
    display: flex;
}

.features-area .features-items li .info {
    padding-left: 20px;
}

.features-area .features-items li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}

.features-area .features-items h4 {
    text-transform: capitalize;
    font-weight: 600;
}

.features-area .features-items p {
    margin: 0;
}

.features-area .bottom {
    border: 1px solid #e7e7e7;
    display: flex;
    padding: 30px;
    margin-top: 30px;
}

.features-area .bottom h4 {
    text-transform: uppercase;
    font-weight: 600;
    color: #666666;
}

.features-area .info h2 {
    font-weight: 600;
    margin-bottom: 25px;
}

.features-area .bottom .content {
    padding-left: 30px;
}

.features-area .bottom .content p {
    margin: 0;
}

.features-area .bottom .video .video-play-button i {
    font-size: 30px;
}

/* ==============================================================
     # Services
=================================================================== */

.services-area .equal-height {
    margin-bottom: 30px;
}

.services-area.inc-thumb .item {
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.services-area.inc-thumb .item .info {
    background: #ffffff;
    padding: 30px;
    padding-top: 55px;
}

.services-area.inc-thumb .item .info h4 {
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 0;
}

.services-area.inc-thumb .item .thumb {
    position: relative;
    z-index: 1;
}

.services-area.inc-thumb .item .thumb::after {
    position: absolute;
    left: 0;
    bottom: -2px;
    content: "";
    height: 3px;
    width: 100%;
    background: #0cb8b6;
}

.services-area.inc-thumb .item .thumb .overlay {
    position: absolute;
    left: 50%;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    bottom: 0;
    background: #ffffff;
    height: 80px;
    z-index: 1;
    margin-left: -40px;
    margin-bottom: -40px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 80px;
    font-size: 35px;
    color: #0cb8b6;
    line-height: 80px;
}


/* Services With Icon ONly */
.services-area.inc-icon .item {
    background: #ffffff;
    position: relative;
    padding: 50px 30px 50px;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-top: 3px solid #0cb8b6;
    z-index: 1;
    overflow: hidden;
}

.services-area.inc-icon .item i {
    height: 100px;
    width: 100px;
    line-height: 100px;
    color: #ffffff;
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-bottom: 30px;
    font-size: 45px;
    background: #0cb8b6;
    position: relative;
    z-index: 1;
    text-align: center;
}

.services-area.inc-icon.less-info .item i {
    height: 90px;
    width: 90px;
    line-height: 90px;
    margin-bottom: 20px;
    font-size: 40px;
    color: #232323;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: transparent;
}

.services-area.inc-icon.less-info .item i::after {
    display: none;
}

.services-area.inc-icon .item i::after {
    position: absolute;
    left: -8px;
    top: -8px;
    z-index: -1;
    content: "";
    height: 116px;
    width: 116px;
    opacity: 0.3;
    border: 2px solid #0cb8b6;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.services-area.inc-icon .item h4 {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 35px;
}

.services-area.inc-icon .item h5 {
    color: #0cb8b6;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.services-area.inc-icon .item .info > a {
    margin-top: 10px;
}

.services-area.inc-icon .item::after {
    position: absolute;
    left: 0;
    top: -100%;
    content: "";
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: #0cb8b6;
}

.services-area.inc-icon .item:hover::after {
    top: 0;
}

.services-area.inc-icon .item h4,
.services-area.inc-icon .item p,
.services-area.inc-icon .item a,
.services-area.inc-icon .item i {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
}

.services-area.inc-icon .item:hover h4,
.services-area.inc-icon .item:hover p,
.services-area.inc-icon .item:hover a {
    color: #ffffff;
}

.services-area.inc-icon .item:hover a.btn {
    color: #ffffff !important;
    border-color: #ffffff;
}

.services-area.inc-icon .item:hover i {
    color: #0cb8b6;
    background: #ffffff;
}

.services-area.inc-icon .item:hover i::after {
    opacity: 0.3;
    border: 2px solid #ffffff;
}

.services-area .services-carousel.owl-carousel .owl-nav {
    margin: 0;
}

.services-area .services-carousel.owl-carousel .owl-nav .owl-prev,
.services-area .services-carousel.owl-carousel .owl-nav .owl-next {
    background: transparent none repeat scroll 0 0;
    color: #666666;
    font-size: 30px;
    height: 40px;
    left: -50px;
    line-height: 40px;
    margin: -20px 0 0;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 40px;
}

.services-area.bg-gray .services-carousel.owl-carousel .owl-nav .owl-prev,
.services-area.bg-gray .services-carousel.owl-carousel .owl-nav .owl-next {
    color: #0cb8b6;
}

.services-area .services-carousel.owl-carousel .owl-nav .owl-next {
    left: auto;
    right: -50px;
}

.services-area .services-carousel.owl-carousel .owl-dots .owl-dot span {
    height: 20px;
    width: 20px;
    background: transparent;
    border: 2px solid #0cb8b6;
}

.services-area .services-carousel.owl-carousel .owl-dots .owl-dot.active span {
    background: #0cb8b6;
}

.services-area.inc-icon.less-info .item h4 {
    margin: 0;
}

.services-area.inc-icon.less-info .item a {
    display: block;
}

/* Services Solid */
.solid-services-area .item i {
    display: inline-block;
    color: #0cb8b6;
    font-size: 60px;
    margin-bottom: 30px;
}

.solid-services-area .item h4 {
    text-transform: capitalize;
    font-weight: 600;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 15px;
}

.solid-services-area .equal-height {
    margin-bottom: 30px;
}

.solid-services-area .item p {
    margin: 0;
}

.solid-services-area .item {
    padding: 67px 40px 64px;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: #ffffff;
}

.solid-services-area .item li {
    display: block;
    position: relative;
    z-index: 1;
    padding-left: 25px;
    margin-bottom: 10px;
}

.solid-services-area .item li::after {
    position: absolute;
    left: 0;
    content: "\f058";
    color: #0cb8b6;
    height: 10px;
    width: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
}

.solid-services-area .item li:last-child {
    margin-bottom: 0;
}


/* ==============================================================
     # Services Area
=================================================================== */
.top-services-area .services-tabs {
    background: #f7f7f7;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 70px;
}

.top-services-area .services-tabs ul.nav.nav-pills {
    margin: 0;
    margin-bottom: -10px;
}

.top-services-area .services-tabs ul.nav.nav-pills li {
    display: inline-block;
}

@media only screen and (min-width: 992px) {
    .top-services-area .services-tabs ul.nav.nav-pills li {
        display: inline-block;
        width: 33.333%;
        margin: 0;
        text-align: left;
    }

}

.top-services-area .services-tabs ul.nav.nav-pills li a {
    display: flex;
    padding: 50px;
    text-transform: uppercase;
    align-items: center;
}

.top-services-area .services-tabs ul.nav.nav-pills li a i {
    display: inline-block;
    font-size: 45px;
    margin-right: 20px;
}

.top-services-area .services-tabs ul.nav.nav-pills li.active a {
    position: relative;
    z-index: 1;
    background: transparent;
    color: #0cb8b6;
}

.top-services-area .services-tabs ul.nav.nav-pills li.active a::after {
    position: absolute;
    left: 0;
    top: -10%;
    background: #ffffff;
    content: "";
    height: 120%;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: -1;
}

.top-services-area .info {
    padding-left: 35px;
}

.top-services-area .info ul {
    margin-top: 25px;
}

.top-services-area .info ul li {
    display: block;
    margin-top: 10px;
    font-weight: 500;
    color: #232323;
    padding-left: 25px;
    position: relative;
    z-index: 1;
}

.top-services-area .info ul li::after {
    position: absolute;
    left: 0;
    top: 2px;
    content: "\4e";
    font-family: 'ElegantIcons';
    text-align: center;
    border-radius: 50%;
    font-weight: 700;
    color: #0cb8b6;
}

.top-services-area .info h2 {
    font-weight: 600;
    margin-bottom: 25px;
}

.top-services-area .info a {
    margin-top: 30px;
}

.top-services-area .info ul.working-hours {
    margin: 0;
}

.top-services-area .info ul.working-hours li {
    padding-left: 0;
    padding: 12px 25px;
    margin: 0;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    border-radius: 5px;
}

.top-services-area .info ul.working-hours li::after {
    display: none;
}

.top-services-area .info ul.working-hours li:nth-child(2n) {
    background: #0cb8b6;
    color: #ffffff;
}

.top-services-area .info ul.working-hours li .closed {
    color: red;
}

.top-services-area .thumb {
    position: relative;
    z-index: 1;
}

.top-services-area .thumb::after {
    position: absolute;
    left: -50px;
    bottom: -50px;
    height: 200px;
    width: 200px;
    background: url(/assets/img/bg-dotted.png);
    content: "";
    z-index: -1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
    opacity: 0.4;
}

/* Services Details */
.services-details-area .widget {
    padding-bottom: 50px;
    background: #ffffff;
    margin-bottom: -5px;
}

.services-details-area .widget:last-child {
    padding-bottom: 0;
}

.services-details-area .widget .appoinment-box {
    padding: 0;
    box-shadow: none;
}

.services-details-area .widget .title h4 {
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    background: #f4f4f4;
    text-transform: uppercase;
    padding: 15px;
    text-align: center;
    color: #666666;
    border-bottom: 1px solid #e7e7e7;
}

.services-details-area .widget.link li {
    display: block;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.services-details-area .widget.link li a {
    display: inline-block;
}

.services-details-area .widget.link li a:hover {
    color: #0cb8b6;
}

.services-details-area .widget.link li:last-child {
    margin: 0;
    padding: 0;
    border: none;
}

.services-details-area .widget.link li i {
    margin-right: 3px;
    color: #0cb8b6;
}

.services-details-area .widget .info {
    padding: 20px 15px;
    border: 1px solid #e7e7e7;
}

.services-details-area .widget .info h4 {
    font-weight: 600;
    text-transform: capitalize;
    color: #666666;
}

.services-details-area .widget .info h5 {
    margin: 0;
    text-transform: uppercase;
    color: #0cb8b6;
}

.services-details-area .widget .thumb {
    position: relative;
    z-index: 1;
}

.services-details-area .widget .thumb .overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 20px;
    z-index: 1;
    right: 0;
}

.services-details-area .widget .thumb::after {
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 160%) repeat scroll 0 0;
    content: "";
    height: 30%;
    width: 100%;
}

.services-details-area .widget .thumb .overlay h4 {
    text-transform: capitalize;
    font-weight: 600;
    color: #ffffff;
}

.services-details-area .widget .thumb .overlay h5 {
    margin: 0;
    color: #0cb8b6;
    text-transform: uppercase;
}

.services-details-area .widget.opening-hours li {
    display: block;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.services-details-area .widget.opening-hours li:last-child {
    margin: 0;
    padding: 0;
    border: none;
}

.services-details-area .content .info {
    margin-top: 30px;
}

.services-details-area .content .info h2,
.services-details-area .content .info h3 {
    font-weight: 600;
}

.services-details-area .content .info h4 {
    text-transform: uppercase;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 15px;
    margin-bottom: 20px;
    margin-top: 25px;
}

.services-details-area .content .info ul li {
    position: relative;
    display: block;
    padding-left: 25px;
    margin-bottom: 15px;
}

.services-details-area .content .info ul li::after {
    position: absolute;
    left: 0;
    top: 0;
    color: #0cb8b6;
    font-weight: 600;
    content: "\f5ae";
    font-family: "Font Awesome 5 Free";
}

.services-details-area .content .info a {
    margin-top: 10px;
}


/* ==============================================================
     # Tabs Area
=================================================================== */
.tabs-items .nav-pills {
    margin-bottom: 30px;
}

.tabs-items .nav-pills li {
    float: left;
}

.tabs-items .nav-pills li a {
    display: inline-block;
    padding: 10px;
    border: 1px solid #e7e7e7;
    margin-right: 5px;
    position: relative;
    background: #f9f9f9;
    border-radius: inherit;
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.tabs-items.bg-gray .nav-pills li a {
    background: #ffffff;
}

.tabs-items .nav-pills li a i {
    float: left;
    font-size: 25px;
    color: #666666;
    margin-right: 10px;
}

.tabs-area .tabs-items .tab-content {
    padding: 30px;
    border: 1px solid #0cb8b6;
}

.tabs-items .nav-pills li.active a,
.tabs-items .nav-pills li:focus a {
    background: #0cb8b6;
}

.tabs-items .nav-pills li.active a i {
    color: #ffffff;
}

.tabs-items .nav-pills li.active a::after {
    position: absolute;
    left: 50%;
    margin-left: -8px;
    bottom: -15px;
    content: "";
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #0cb8b6;
}

.tabs-items .tab-content h4, .tabs-items .tab-content h3 {
    font-weight: 600;
}

.tabs-items .tab-content p:last-child {
    margin-bottom: 0;
}

.tabs-items .tab-content iframe {
    width: 100%;
    min-height: 250px;
    margin-top: 15px;
    margin-bottom: -10px;
}

.tabs-items .tab-content li {
    display: block;
    border: 1px solid #e7e7e7;
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    padding: 15px;
}

.tabs-items .tab-content li:last-child {
    margin: 0;
}

.tabs-items .tab-content li:nth-child(odd) {
    background: #f4f4f4;
}


/* ==============================================================
     # Fun Factor
=================================================================== */

.fun-fact-area .fun-fact {
    padding: 30px;
    position: relative;
    z-index: 1;
}

.fun-fact-area .fun-fact::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 100%;
    background: #ffffff;
    z-index: -1;
    opacity: 0.9;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.fun-fact-area .fun-fact i {
    display: inline-block;
    font-size: 50px;
    margin-bottom: 25px;
    color: #0cb8b6;
}

.fun-fact-area .fun-fact .timer {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
}

.fun-fact-area .fun-fact .timer::after {
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    position: absolute;
    top: 0;
}

.fun-fact-area .fun-fact .medium {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
}

/* ==============================================================
     # Video BG
=================================================================== */
.video-bg-area {
    padding: 150px 0;
}

.video-bg-area h1 {
    text-transform: capitalize;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 30px;
    margin-top: -8px;
}

/* ==============================================================
     # Appoinment
=================================================================== */

.appoinment-box {
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 40px;
    background: #ffffff;
}

.appoinment .heading {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 25px;
}

.appoinment .heading p:last-child {
    margin-bottom: 0;
}

.appoinment .heading::after {
    background: #0cb8b6 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 40px;
}

.appoinment .heading::before {
    background: #0cb8b6 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 45px;
    position: absolute;
    width: 5px;
}

.appoinment form input {
    box-shadow: inherit;
    border: 1px solid #e7e7e7;
    border-radius: inherit;
    padding: 15px;
    color: #232323;
}

.appoinment form textarea {
    box-shadow: inherit;
    border: 1px solid #e7e7e7;
    padding: 15px;
    min-height: 120px;
    border-radius: inherit;
}

.appoinment form button {
    background: transparent;
    padding: 10px 35px;
    margin-top: 15px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    border: 2px solid transparent;
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    color: #232323;
    border: 2px solid #0cb8b6;
    background: transparent;
}

.appoinment form button:hover {
    background: #0cb8b6;
    color: #ffffff;
    border: 2px solid transparent;
}

.appoinment form button i {
    margin-left: 3px;
}

.department-tabs .tab-content p:last-child {
    margin-bottom: 0;
}

.appoinment h4 {
    color: #666666;
    text-transform: uppercase;
}

.appoinment h2,
.appoinment h3 {
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 0;
}


.appoinment-box .appointment-schedule {
    margin-bottom: 30px;
    text-align: center;
}

.appoinment-box .appointment-schedule h5 {
    font-weight: 700;
    text-transform: uppercase;
    background: #0cb8b6;
    padding: 15px 35px;
    border-radius: 5px;
    color: #ffffff;
    letter-spacing: 0.6px;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
}

.appoinment-box .appointment-schedule li {
    display: block;
    margin-bottom: 10px;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 10px;
}

.appoinment-box .appointment-schedule li:last-child {
    margin: 0;
    padding: 0;
    border: none;
}

.appoinment-box .appointment-schedule li span {
    float: right;
}

.appoinment-box .appointment-schedule ul {
    text-align: left;
}

/* Nice Select CSS */
.nice-select {
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
    border: solid 1px #e8e8e8;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-family: inherit;
    font-size: 14px;
    font-weight: normal;
    height: 50px;
    line-height: 50px;
    outline: none;
    padding-left: 18px;
    padding-right: 30px;
    position: relative;
    text-align: left !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    width: 100%;
    margin-bottom: 15px;
}

.nice-select:hover {
    border-color: #dbdbdb;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
    border-color: #999;
}

.nice-select:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    width: 5px;
}

.nice-select.open:after {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
}

.nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none;
}

.nice-select.disabled:after {
    border-color: #cccccc;
}

.nice-select.wide {
    width: 100%;
}

.nice-select.wide .list {
    left: 0 !important;
    right: 0 !important;
}

.nice-select.right {
    float: right;
}

.nice-select.right .list {
    left: auto;
    right: 0;
}

.nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px;
}

.nice-select.small:after {
    height: 4px;
    width: 4px;
}

.nice-select.small .option {
    line-height: 34px;
    min-height: 34px;
}

.nice-select .list {
    background-color: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    -webkit-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    -o-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(0.75) translateY(-21px);
    -ms-transform: scale(0.75) translateY(-21px);
    transform: scale(0.75) translateY(-21px);
    -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9;
    width: 100%;
}

.nice-select .list:hover .option:not(:hover) {
    background-color: transparent !important;
}

.nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
    background-color: #f6f6f6;
}

.nice-select .option.selected {
    font-weight: bold;
}

.nice-select .option.disabled {
    background-color: transparent;
    color: #999;
    cursor: default;
}

.no-csspointerevents .nice-select .list {
    display: none;
}

.no-csspointerevents .nice-select.open .list {
    display: block;
}


/* Banner Appoinment */
.banner-area .appoinment-box {
    position: relative;
    z-index: 1;
    background: transparent;
}

.banner-area .appoinment-box::after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 100%;
    background: #ffffff;
    z-index: -1;
    opacity: 0.7;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.banner-area .appoinment-box input,
.banner-area .appoinment-box .nice-select {
    border-color: #e7e7e7;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.banner-area .appoinment-box .nice-select span {
    color: #666666;
}

.banner-area .appoinment-box h2 {
    color: #232323;
}

.banner-area .appoinment-box .heading::after {
    background: #232323;
}

.banner-area .appoinment-box .heading::before {
    background: #232323;
}

.banner-area .appoinment-box button {
    background: #0cb8b6;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    color: #ffffff;
}


/* ==============================================================
     # Faq Accordion
=================================================================== */

.faq-area {
    overflow: hidden;
}

.faq-items .panel-group,
.acd-items .panel-group {
    margin-bottom: 0;
}

.faq-area .faq-items {
    margin-top: -15px;
}

.acd-items .panel-heading .panel-title a span {
    background: #ff5a6e none repeat scroll 0 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color: #ffffff;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    text-align: center;
    width: 30px;
}

.acd-items .panel-heading .panel-title a {
    color: #232323;
    display: block;
    font-weight: 600;
    padding: 19px 30px 20px 15px;
    position: relative;
}

.acd-items .panel-heading {
    padding: 0;
}

.faq-area .acd-items .panel-body {
    padding: 30px 0 20px;
}

.acd-items .panel.panel-default {
    border: none;
    border-radius: inherit;
    box-shadow: inherit;
    margin: 0 0 10px;
}

.acd-items .panel.panel-default:last-child {
    margin-bottom: 0;
}

.acd-items .panel-body p:last-child {
    margin: 0;
}

.faq-items .panel-body {
    padding: 20px;
}

.faq-items .acd-items .panel .panel-body {
    border-color: #e7e7e7;
}

.acd-arrow .panel-group.symb .panel-heading a::after {
    content: "\f107";
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 17px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #232323;
}

.acd-arrow.left .panel-group.symb .panel-heading a::after {
    content: "\f01b" !important;
    font-size: 20px;
    position: absolute;
    right: auto;
    left: 10px;
    top: 12px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

.acd-arrow.left .panel-group.symb .panel-heading a.collapsed:after {
    content: "\f01a" !important;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

.faq-area .faq-items .panel-group {
    margin: 0;
}

.acd-arrow .panel-group.symb .panel-heading a.collapsed:after {
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

.acd-items .panel.panel-default:last-child .panel-body {
    padding-bottom: 0;
    margin-bottom: -5px;
}

.faq-area .heading h2 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 25px;
}

/* ==============================================================
     # Chose Us
=================================================================== */

.container-full .row {
    display: flex;
}

.container-full .row > .info {
    padding: 80px 0 75px 80px;
    max-width: 600px;
}

.chose-us-area.item-half .container-full .row > .info {
    padding: 120px 0 115px 50px;
}

.chose-us-area.item-half .info li {
    float: left;
    width: 50%;
    margin-top: 30px;
    margin-bottom: 0;
}

.chose-us-area.item-half .info li:first-child,
.chose-us-area.item-half .info li:nth-child(2) {
    margin-top: 0;
}

.chose-us-area.item-half .info li:nth-child(2n) {
    padding-left: 15px;
}

.chose-us-area .info .heading {
    position: relative;
    z-index: 1;
}

.chose-us-area .info li .icon i {
    display: inline-block;
    font-size: 50px;
    color: #0cb8b6;
}

.chose-us-area .info li .icon {
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
}

.chose-us-area .info li {
    display: block;
    margin-bottom: 40px;
}

.chose-us-area .info li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}

.chose-us-area .info li h4 {
    font-weight: 600;
    display: inline-block;
}

.chose-us-area .info h2 {
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 20px;
}

.chose-us-area .info p:last-child {
    margin-bottom: 0;
}

.chose-us-area .info ul {
    margin-top: 30px;
    overflow: hidden;
}

.chose-us-area .info li p:last-child {
    margin: 0;
}

.chose-us-area .info li .title {
    display: flex;
    margin-bottom: 15px;
    border-bottom: 1px solid #e7e7e7;
    align-items: center;
    padding-bottom: 15px;
}

.chose-us-area .info li .info {
    position: relative;
    z-index: 1;
    padding-left: 35px;
}

.chose-us-area .info li .info::after {
    position: absolute;
    left: 0;
    top: 0;
    font-family: "Font Awesome 5 Free";
    content: "\f00c";
    font-weight: 900;
    font-size: 20px;
    color: #0cb8b6;
}

.chose-us-area .appoinment {
    padding-right: 35px;
}


/* ==============================================================
     # Top Content
=================================================================== */
.top-content-area .item {
    display: flex;
}

.top-content-area .item > div {
    width: 50%;
}

.top-content-area .item .thumb {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.top-content-area .item .info {
    padding: 50px 30px;
}

.top-cotent-items {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


/* ==============================================================
     # Doctor Tips
=================================================================== */
.health-tips-items .thumb {
    position: relative;
    z-index: 1;
}

.health-tips-items .thumb .overlay {
    position: absolute;
    left: 0;
    bottom: -30px;
    text-align: center;
    padding: 20px;
    background: #0cb8b6;
}

.health-tips-items .thumb .overlay img {
    padding-left: 30px;
}

.health-tips-items .doctor {
    margin-bottom: 25px;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 15px;
}

.health-tips-items .doctor h5 {
    color: #0cb8b6;
}

.health-tips-items .thumb .overlay h4 {
    text-transform: uppercase;
    color: #ffffff;
}

.health-tips-items .thumb .overlay h5 {
    color: #ffffff;
    letter-spacing: 1px;
    font-weight: 400;
    margin: 0;
}

.health-tips-items .info {
    background: #ffffff;
    padding: 30px;
    position: relative;
    z-index: 1;
}

.health-tips-items .info::after {
    position: absolute;
    left: -18px;
    content: "";
    top: 50px;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-right: 30px solid #ffffff;
}

.health-tips-items .info h4 {
    text-transform: uppercase;
    font-weight: 600;
}

.health-tips-items .info ul li {
    display: block;
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
    padding-left: 25px;
}

.health-tips-items .info ul li::after {
    position: absolute;
    left: 0;
    top: 0;
    font-family: "Font Awesome 5 Free";
    content: "\f067";
    height: 15px;
    width: 15px;
    font-weight: 600;
    color: #0cb8b6;
}

.health-tips-items .info ul li:last-child {
    margin: 0;
}

.doctor-tips-area .tips-carousel.owl-carousel .owl-nav {
    margin: 0;
}

.doctor-tips-area .tips-carousel.owl-carousel .owl-nav .owl-prev,
.doctor-tips-area .tips-carousel.owl-carousel .owl-nav .owl-next {
    background: transparent none repeat scroll 0 0;
    color: #666666;
    font-size: 30px;
    height: 40px;
    left: -35px;
    line-height: 40px;
    margin: -20px 0 0;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 40px;
}

.doctor-tips-area.bg-gray .tips-carousel.owl-carousel .owl-nav .owl-prev,
.doctor-tips-area.bg-gray .tips-carousel.owl-carousel .owl-nav .owl-next {
    color: #0cb8b6;
}

.doctor-tips-area .tips-carousel.owl-carousel .owl-nav .owl-next {
    left: auto;
    right: -35px;
}


/* ==============================================================
     # Doctor
=================================================================== */

.doctor-area .equal-height {
    margin-bottom: 30px;
}

.doctor-items .thumb {
    position: relative;
    z-index: 1;
}

.doctor-items .thumb .overlay {
    position: absolute;
    left: 50%;
    bottom: -25px;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
}

.doctor-items .thumb .overlay i {
    height: 50px;
    width: 50px;
    background: #0cb8b6;
    line-height: 50px;
    color: #ffffff;
    font-size: 15px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.doctor-items .info {
    padding: 40px 30px 30px;
    border-top: 2px solid #0cb8b6;
    background: #ffffff;
}

.doctor-items .item {
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.doctor-items .info h4 {
    text-transform: capitalize;
    font-weight: 600;
}

.doctor-items .info h5 {
    text-transform: uppercase;
    color: #0cb8b6;
    margin-bottom: 15px;
    font-size: 12px;
    letter-spacing: 1px;
}

/* Social */
.doctor-items .thumb .social {
    position: absolute;
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    bottom: 80px;
    width: 100%;
    opacity: 0;
}

.doctor-items .item:hover .social {
    opacity: 1;
    bottom: 40px;
}

.doctor-items .item:hover img {
    opacity: 0.5;
}

.doctor-items .thumb img {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
}

.doctor-items .social li {
    display: inline-block;
}

.doctor-items .social li a {
    display: inline-block;
    height: 40px;
    width: 40px;
    background: rgba(255, 255, 255, 0.9);
    line-height: 40px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin: 0 1px;
}

.doctor-items .social li.twitter a {
    background-color: #00b6f1;
    color: #ffffff;
}

.doctor-items .social li.pinterest a {
    background-color: #bd081c;
    color: #ffffff;
}

.doctor-items .social li.facebook a {
    background-color: #3b5998;
    color: #ffffff;
}

.doctor-items .social li.g-plus a {
    background-color: #df4a32;
    color: #ffffff;
}

.doctor-items .social li.vimeo a {
    background-color: #1ab7ea;
    color: #ffffff;
}

.doctor-items .social li.instagram a {
    background-color: #cd486b;
    color: #ffffff;
}

.doctor-items .social li.linkedin a {
    background-color: #0077b5;
    color: #ffffff;
}

.doctor-items .social li a:hover {
    background: #0cb8b6;
    color: #ffffff;
}

.doctor-items .info ul {
    margin-top: 20px;
}

.doctor-items .info .appoinment-btn {
    position: relative;
    z-index: 1;
}

.doctor-items .info .appoinment-btn::after {
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    height: 1px;
    width: 100%;
    background: #e7e7e7;
    z-index: -1;
}

.doctor-items .info a {
    display: inline-block;
    text-transform: uppercase;
    color: #666666;
    background: #ffffff;
    position: relative;
    z-index: 1;
    padding: 0 10px;
}

.doctor-area .doctor-carousel.owl-carousel .owl-nav {
    margin: 0;
}

.doctor-area .doctor-carousel.owl-carousel .owl-nav .owl-prev,
.doctor-area .doctor-carousel.owl-carousel .owl-nav .owl-next {
    background: transparent none repeat scroll 0 0;
    color: #666666;
    font-size: 30px;
    height: 40px;
    left: -50px;
    line-height: 40px;
    margin: -20px 0 0;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 40px;
}

.doctor-area .doctor-carousel.owl-carousel .owl-nav .owl-prev,
.doctor-area .doctor-carousel.owl-carousel .owl-nav .owl-next {
    color: #0cb8b6;
}

.doctor-area .doctor-carousel.owl-carousel .owl-nav .owl-next {
    left: auto;
    right: -50px;
}

/* ==============================================================
     # Doctor Details
=================================================================== */
.doctor-details-area .nav-pills {
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 30px;
    margin-top: 25px;
}

.doctor-details-area .nav-pills li a {
    position: relative;
    z-index: 1;
    padding: 0;
    text-transform: uppercase;
    background: transparent;
    padding-bottom: 15px;
}

.doctor-details-area .nav-pills li.active a {
    background: transparent;
    color: #0cb8b6;
}

.doctor-details-area .nav-pills li {
    margin-right: 25px;
}

.doctor-details-area .nav-pills li.active::after {
    position: absolute;
    left: 0;
    bottom: -2px;
    content: "";
    height: 3px;
    width: 100%;
    background: #0cb8b6;
}

.doctor-details-area .info a {
    margin-top: 10px;
}

.doctor-details-area .info h4 {
    margin-bottom: 25px;
    color: #666666;
    text-transform: uppercase;
}

.doctor-details-area .tab-content p:last-child {
    margin-bottom: 0;
}

.doctor-details-area .tab-content {
    margin-bottom: -10px;
}

.doctor-details-area .thumb {
    position: relative;
    z-index: 1;
}

.doctor-details-area .thumb::after {
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 160%) repeat scroll 0 0;
    content: "";
    height: 30%;
    width: 100%;
}

.doctor-details-area .thumb .overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 20px;
    text-align: center;
    right: 0;
    z-index: 1;
}

.doctor-details-area .thumb .overlay li {
    display: inline-block;
}

.doctor-details-area .thumb .overlay li a {
    display: inline-block;
    font-size: 20px;
    color: #ffffff;
    margin: 0 10px;
}

.doctor-details-area .tab-content li {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    padding: 10px;
}

.doctor-details-area .tab-content li:nth-child(odd) {
    background: #f4f4f4;
}

/* ==============================================================
     # Departments
=================================================================== */

.department-items .equal-height {
    margin-bottom: 30px;
}

.department-items .thumb {
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.department-items .thumb img {
    display: block;
    position: relative;
    max-width: none;
    width: calc(100% + 60px);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.45s;
    transition: opacity 0.35s, transform 0.45s;
    -webkit-transform: translate3d(-40px, 0, 0);
    transform: translate3d(-40px, 0, 0);
}

.department-items .item:hover img {
    opacity: 0.6;
    filter: alpha(opacity=60);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.department-items .item .info {
    padding: 30px;
    overflow: hidden;
    border: 1px solid #e7e7e7;
    background: #ffffff;
}

.department-items .item .info li {
    float: left;
    width: 50%;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
}

.department-items .item .info li span {
    display: block;
    color: #0cb8b6;
    font-weight: 700;
    font-size: 20px;
    margin-top: 5px;
    letter-spacing: 1px;
}

.department-items .item .info ul {
    border-top: 1px solid #e7e7e7;
    overflow: hidden;
    padding-top: 15px;
    text-align: center;
}

.department-items .item .info h4 {
    text-transform: capitalize;
    font-weight: 600;
}

/* ==============================================================
     # Gallery
=================================================================== */

.gallery-area {
    padding-bottom: 105px;
}

.mix-item-menu button {
    border: none;
    background: transparent;
    display: inline-block;
    text-transform: uppercase;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    font-weight: 600;
    position: relative;
    padding: 0 15px;
    cursor: pointer;
    z-index: 1;
    font-family: 'Poppins', sans-serif;
}

.mix-item-menu {
    display: inline-block;
    border: 1px solid #e7e7e7;
    padding: 15px 25px;
    margin-bottom: 30px;
}

.mix-item-menu button.active::after {
    position: absolute;
    left: 50%;
    top: 3px;
    content: "";
    height: 20px;
    width: 2px;
    background: #0cb8b6;
    margin-left: -2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    z-index: -1;
}

.mix-item-menu button.active {
    color: #0cb8b6;
}

.gallery-area.inc-colum {
    padding-bottom: 105px;
}

.gallery-area .gallery-items.col-3 .pf-item {
    float: left;
    padding: 15px;
    width: 33.3333%;
}

.gallery-area .gallery-items.col-2 .pf-item {
    float: left;
    padding: 15px;
    width: 50%;
}

.gallery-area .gallery-items.col-4 .pf-item {
    float: left;
    padding: 15px;
    width: 25%;
}

.gallery-area .gallery-items.col-6 .pf-item {
    float: left;
    padding: 15px;
    width: 16.6666%;
}


/* Portfolio Effects */
/* generic css */

.effect-box {
    position: relative;
    overflow: hidden;
}

.effect-box::after {
    position: absolute;
    left: 5%;
    top: 5%;
    content: "";
    height: 90%;
    background: #ffffff;
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    opacity: 0;
    width: 90%;
}

.gallery-items .pf-item:hover .effect-box::after {
    opacity: 0.7;
}

.effect-box .info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    width: 100%;
    padding: 30px;
    z-index: 1;
}

.effect-box img {
    display: block;
    position: relative;
}

.effect-box h4,
.effect-box h4 a {
    text-transform: uppercase;
    font-weight: 500;
}

.effect-box img {
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
}

.effect-box h4 {
    transform: translateY(-100px);
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
    opacity: 0;
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
}

.effect-box p {
    transform: translateY(100px);
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -ms-transform: translateY(100px);
    -o-transform: translateY(100px);
    opacity: 0;
    transition: all 0.35s linear;
    -webkit-transition: all 0.35s linear;
    -moz-transition: all 0.35s linear;
    -ms-transition: all 0.35s linear;
    -o-transition: all 0.35s linear;
}

.effect-box .info > a {
    opacity: 0;
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    display: inline-block;
    height: 45px;
    width: 45px;
    background: #0cb8b6;
    display: inline-block;
    line-height: 45px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin: 0 3px;
    color: #ffffff;
}


/* Gallery Effect */

.effect-box:hover img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.effect-box:hover .info {
    opacity: 1;
}

.effect-box:hover h4,
.effect-box:hover p,
.effect-box:hover a {
    opacity: 1;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
}

.effect-box:hover p {
    transition-delay: 0.1s;
    -webkit-transition-delay: 0.1s;
    -moz-transition-delay: 0.1s;
    -ms-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
}

.effect-box:hover a {
    transition-delay: 0.2s;
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    -ms-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
}


/* ==============================================================
     # Department Tabs
=================================================================== */
.department-tabs .tab-navs li {
    width: 100%;
    margin-bottom: 15px;
}

.department-tabs .tab-navs li i {
    font-size: 30px;
    margin-right: 15px;
    position: relative;
    float: left;
    top: -2px;
}

.department-tabs .tab-navs li a {
    padding: 15px 30px;
    background: #ffffff;
    border: 1px solid #e7e7e7;
}

.department-tabs .tab-navs li.active a {
    background: #0cb8b6;
    border-color: transparent;
}

.department-tabs .heading h4 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 25px;
}

.department-tabs .tab-content .thumb {
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
}

.department-tabs .tab-content h3 {
    text-transform: capitalize;
    font-weight: 600;
}

.department-tabs .tab-content .opening-info {
    padding: 30px;
    background: #ffffff;
    border: 1px solid #e7e7e7;
}

.department-tabs .tab-content .opening-info h4 {
    position: relative;
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.department-tabs .tab-content .opening-info h4::after {
    background: #0cb8b6 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 40px;
}

.department-tabs .tab-content .opening-info h4::before {
    background: #0cb8b6 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 45px;
    position: absolute;
    width: 5px;
}

.department-tabs .tab-content .opening-info li {
    padding: 15px;
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: #666666;
}

.department-tabs .tab-content .opening-info li:nth-child(2n) {
    background: #f1f1f1;
}

.department-tabs .tab-content .opening-info li:first-child {
    padding-top: 0;
}

.department-tabs .tab-content .opening-info li:last-child {
    padding-bottom: 0;
    padding-top: 20px;
}

.department-tabs .tab-content .opening-info li .pull-right {
    padding: 0 15px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

.department-tabs .tab-content .opening-info li .pull-right.closed {
    background: #0cb8b6;
    color: #ffffff;
}

/* ==============================================================
     # Testimonials
=================================================================== */

.testimonials-area.carousel-shadow .owl-stage-outer {
    padding: 25px 15px 15px;
}

.testimonial-items .item {
    background: #ffffff;
    padding: 40px 30px 30px;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.testimonial-items .item::after {
    position: absolute;
    left: 50px;
    top: -15px;
    content: "\f10d";
    font-family: "Font Awesome 5 Free";
    text-align: center;
    font-size: 50px;
    color: #0cb8b6;
    font-weight: 900;
}

.testimonial-items .provider {
    display: flex;
    margin-top: 20px;
    align-items: center;
    border-top: 1px solid #e7e7e7;
    padding-top: 20px;
}

.testimonial-items .provider img {
    height: 100px;
    width: 100px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.testimonial-items .provider .thumb {
    margin-right: 15px;
}

.testimonial-items .provider h4 {
    color: #666666;
    text-transform: uppercase;
    font-weight: 600;
}

.testimonial-items .provider h5 {
    text-transform: uppercase;
    font-size: 12px;
    color: #666666;
    letter-spacing: 1px;
}

.testimonial-items .provider h5 span {
    color: #0cb8b6;
}

.testimonial-items .content {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
}

.testimonial-items .content::after {
    position: absolute;
    left: 50px;
    bottom: -15px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #ffffff;
    content: "";
}

.testimonials-area .testimonial-carousel.owl-carousel .owl-nav {
    margin: 0;
}

.testimonials-area .testimonial-carousel.owl-carousel .owl-nav .owl-prev,
.testimonials-area .testimonial-carousel.owl-carousel .owl-nav .owl-next {
    background: transparent none repeat scroll 0 0;
    color: #666666;
    font-size: 30px;
    height: 40px;
    left: -50px;
    line-height: 40px;
    margin: -20px 0 0;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 40px;
}

.testimonials-area .testimonial-carousel.owl-carousel .owl-nav .owl-prev,
.testimonials-area .testimonial-carousel.owl-carousel .owl-nav .owl-next {
    color: #0cb8b6;
}

.testimonials-area .testimonial-carousel.owl-carousel .owl-nav .owl-next {
    left: auto;
    right: -50px;
}

/* ==============================================================
     # Blog
=================================================================== */

.blog-area .thumb {
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.blog-area .thumb .post-type {
    background: rgba(12, 184, 182, 0.7) none repeat scroll 0 0;
    bottom: 20px;
    color: #ffffff;
    font-size: 20px;
    height: 50px;
    right: 20px;
    line-height: 50px;
    position: absolute;
    text-align: center;
    width: 50px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    z-index: 1;
}

.blog-area .thumb img {
    max-width: none;
    width: -webkit-calc(100% + 60px);
    width: calc(100% + 60px);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
}

.blog-area .item:hover .thumb img {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.blog-area .meta li {
    display: block;
    text-transform: uppercase;
}

.blog-area .meta .comments {
    position: absolute;
    right: 0;
    top: 25px;
}

.blog-area .meta .comments a {
    font-weight: 400;
    color: #666666 !important;
    font-family: 'Open Sans', sans-serif;
}

.blog-area .meta .comments a:hover {
    color: #0cb8b6 !important;
}

.blog-area .meta .comments i {
    margin-right: 3px;
}

.blog-area .info {
    padding-top: 30px;
}

.blog-area .info > a {
    margin-top: 5px;
}

.blog-area .info .meta a {
    text-transform: uppercase;
    color: #0cb8b6;
}

.blog-area .meta {
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;
}

.blog-area .info h4 {
    line-height: 1.5;
}

.blog-area .info h2,
.blog-area .info h3 {
    line-height: 1.3;
}

.blog-area .info a:hover {
    color: #0cb8b6;
}

.blog-area.full-blog .single-item {
    margin-bottom: 50px;
}

.blog-area.left-sidebar .blog-content {
    float: right;
}


/* Pagination */

.blog-area .pagi-area .pagination li a {
    border: 1px solid #e7e7e7;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    color: #232323;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    margin-right: 10px;
    margin-top: 5px;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    width: 50px;
}

.blog-area .pagi-area .pagination li.active a {
    background: #0cb8b6 none repeat scroll 0 0;
    border-color: transparent;
    color: #ffffff;
}

.blog-area .pagi-area .pagination {
    margin-bottom: -10px;
    margin-top: -5px;
}


/* Sidebar */

.blog-area .sidebar .title {
    display: block;
}

.blog-area .sidebar .title h4 {
    font-weight: 500;
    margin-bottom: 25px;
    margin-top: -5px;
    padding-bottom: 15px;
    position: relative;
    text-transform: uppercase;
    z-index: 1;
}

.blog-area .sidebar .title h4::before {
    background: #0cb8b6 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 40px;
    z-index: -1;
}

.blog-area .sidebar .title h4::after {
    background: #0cb8b6 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 45px;
    position: absolute;
    width: 5px;
    z-index: -1;
}

.blog-area .sidebar input[type="text"] {
    border: 1px solid #e7e7e7;
    border-radius: inherit;
    box-shadow: inherit;
}

.blog-area .sidebar form {
    position: relative;
}

.blog-area .sidebar input[type="submit"] {
    background: #232323 none repeat scroll 0 0;
    border: medium none;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 0 15px;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 0;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}

.blog-area .sidebar input[type="submit"]:hover {
    background: #0cb8b6 none repeat scroll 0 0;
}

.blog-area .sidebar .sidebar-item {
    float: left;
    margin-bottom: 30px;
    width: 100%;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.blog-area .sidebar .sidebar-item:last-child {
    margin-bottom: 0;
}

.blog-area .sidebar .sidebar-item.category .sidebar-info,
.blog-area .sidebar .sidebar-item.archives .sidebar-info {
    margin-top: -5px;
}

.blog-area .sidebar .sidebar-item.category li {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #e7e7e7;
    position: relative;
}

.blog-area .sidebar .sidebar-item.category li a {
    display: inline-block;
    text-transform: capitalize;
    padding-left: 25px;
}

.blog-area .sidebar .sidebar-item li a:hover {
    color: #0cb8b6;
}

.sidebar-item.category ul li a::after {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    content: "\f07b";
    font-family: "Font Awesome 5 Free";
    height: 8px;
    left: 0;
    position: absolute;
    width: 8px;
    top: 10px;
}

.blog-area .sidebar .sidebar-item.category li a span {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    color: #666666;
    font-weight: 500;
    padding: 0 8px;
    position: absolute;
    right: 0;
    top: 10px;
    border: 1px solid #e7e7e7;
}

.blog-area .sidebar .sidebar-item.category li:first-child a span {
    top: 0;
}

.blog-area .sidebar .sidebar-item.category li:first-child {
    margin-top: 0;
    padding-top: 0;
}

.blog-area .sidebar .sidebar-item.category li:first-child a::after {
    top: 0;
}

.blog-area .sidebar .sidebar-item.category li:last-child {
    border: medium none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.sidebar-item.recent-post li a {
    color: #232323;
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-top: 0;
}

.sidebar-item.recent-post li a:last-child {
    display: inline-block;
    font-weight: 400;
    text-transform: uppercase;
}

.sidebar-item.recent-post .meta-title {
    color: #999999;
    font-family: "Poppins", sans-serif;
    margin-top: 10px;
    text-transform: uppercase;
}

.sidebar-item.recent-post li a:hover {
    color: #0cb8b6;
}

.sidebar-item.recent-post li a span {
    display: inline-block;
    color: #232323;
}

.sidebar-item.recent-post li span {
    display: inline-block;
    text-transform: uppercase;
}

.sidebar-item.recent-post .meta-title a {
    text-transform: capitalize;
    color: #999999;
}

.sidebar-item.recent-post li {
    color: #cdd0d3;
}

.sidebar-item.recent-post li {
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.sidebar-item.recent-post li:last-child {
    border: medium none;
    margin: 0;
    padding: 0;
}

.sidebar-item.recent-post li:last-child {
    margin: 0;
}

.sidebar-item.recent-post li .thumb {
    display: table-cell;
    padding-top: 5px;
    vertical-align: top;
    width: 80px;
}

.sidebar-item.recent-post .thumb img {
    width: 100%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    transform: inherit;
}

.sidebar-item.recent-post li .info {
    box-shadow: inherit;
    color: #837f7e;
    display: table-cell;
    line-height: 26px;
    padding: 0 0 0 20px;
    vertical-align: top;
}

.sidebar-item.gallery ul li {
    box-sizing: border-box;
    float: left;
    padding: 0 10px 10px 0;
    width: 33.333%;
    float: left;
}

.sidebar-item.gallery ul li img {
    min-height: 70px;
    width: 100%;
}

.sidebar-item.archives ul li {
    display: block;
    margin-bottom: 20px;
    position: relative;
}

.sidebar-item.archives ul li:last-child {
    margin-bottom: 0;
}

.sidebar-item.archives ul li a {
    color: #666666;
    display: inline-block;
    font-weight: 500;
    text-transform: capitalize;
    z-index: 1;
    padding-left: 25px;
}

.sidebar-item.archives ul li a::after {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    content: "\f07b";
    font-family: "Font Awesome 5 Free";
    height: 8px;
    left: 0;
    position: absolute;
    width: 8px;
}

.sidebar-item.social-sidebar li {
    display: inline-block;
    float: left;
    margin-right: 5px;
}

.sidebar-item.social-sidebar li a {
    background: #232323 none repeat scroll 0 0;
    border: medium none;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color: #ffffff;
    display: inline-block;
    font-size: 16px;
    height: 60px;
    line-height: 65px;
    margin-bottom: 5px;
    text-align: center;
    width: 60px;
}

.sidebar-item.social-sidebar li a:hover {
    color: #ffffff !important;
}

.sidebar-item.social-sidebar li.facebook a {
    background: #3B5998 none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.twitter a {
    background: #1DA1F2 none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.pinterest a {
    background: #BD081C none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.g-plus a {
    background: #DB4437 none repeat scroll 0 0;
}

.sidebar-item.social-sidebar li.linkedin a {
    background: #0077B5 none repeat scroll 0 0;
}

.sidebar-item.tags ul li {
    display: inline-block;
}

.sidebar-item.tags ul li a {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #e7e7e7;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 8px;
    margin-right: 5px;
    padding: 10px 20px;
    text-transform: capitalize;
    font-size: 13px;
    color: #666666;
}

.sidebar-item.tags ul li a:hover {
    color: #0cb8b6;
}


/* Blog Single */
.blog-area.single .item .content-box span {
    background: #0cb8b6 none repeat scroll 0 0;
    color: #ffffff;
    display: inline-block;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 15px;
    padding: 3px 20px;
    text-transform: uppercase;
}

.blog-area.single .item .content-box h2,
.blog-area.single .item .content-box h3,
.blog-area.single .item .content-box h4 {
    font-weight: 500;
}

.blog-area.single .content-box .cats {
    float: left;
    margin-right: 5px;
}

.blog-area.single .content-box .meta .date {
    float: right;
    font-family: "Poppins", sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.blog-area.single .content-box .meta {
    overflow: hidden;
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
}

.blog-area.single .content-box .cats a:last-child::after {
    display: none;
}

.blog-area.single .item {
    margin-bottom: 0;
}

.blog-area .item blockquote {
    border-color: #0cb8b6;
    color: #414141;
    font-size: 15px;
    position: relative;
    font-style: italic;
    z-index: 1;
    margin-top: 30px;
    padding: 0 0 0 20px;
    margin-bottom: 30px;
    border-width: 2px;
}

.author-bio {
    display: block;
    margin-top: 30px;
    width: 100%;
    border: 1px solid #e7e7e7;
    padding: 30px;
}

.blog-area .author-bio img {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.author-bio .avatar {
    display: table-cell;
    vertical-align: top;
    width: 200px;
}

.author-bio .content {
    display: table-cell;
    padding: 0 0 0 30px;
    vertical-align: middle;
}

.author-bio .content p {
    margin: 0;
    position: relative;
    z-index: 1;
    padding-top: 5px;
    color: #232323;
}

.author-bio .content p::after {
    content: "\f10d";
    font-family: "Font Awesome 5 Free";
    font-size: 60px;
    left: 0;
    opacity: 0.1;
    position: absolute;
    top: 0;
    font-weight: 600;
}

.author-bio .content h4 {
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 15px;
    text-transform: uppercase;
}

.blog-area.single .post-pagi-area {
    background: #f4f4f4 none repeat scroll 0 0;
    margin: 30px 0 45px;
    overflow: hidden;
    padding: 15px;
    border: 1px solid #e7e7e7;
}

.blog-area.single .post-pagi-area a {
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
}

.blog-area.single .post-pagi-area a:hover {
    color: #0cb8b6;
}

.blog-area.single .post-pagi-area a:last-child {
    float: right;
}

.blog-area.single .post-pagi-area a:first-child i {
    margin-right: 3px;
}

.blog-area.single .post-pagi-area a:last-child i {
    margin-left: 3px;
}

.blog-area.single .post-tags span {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-right: 5px;
    text-transform: uppercase;
}

.blog-area.single .post-tags a {
    border: 1px solid #e7e7e7;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    color: #232323;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
    padding: 3px 25px;
    text-transform: capitalize;
    margin-bottom: 8px;
}

.comments-area .commen-item .comments-info p {
    display: block;
    margin: 0;
}

.blog-area .contact-comments .submit {
    margin-bottom: 0;
}

.blog-area.single .post-tags a:hover {
    color: #0cb8b6;
}

.blog-area.single.color-yellow .post-tags a:hover {
    color: #ff9800;
}

.responsive-video {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.responsive-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.comments-list .commen-item .avatar {
    float: left;
    height: 100px;
    width: 100px;
}

.comments-list .commen-item .content {
    display: table-cell;
    vertical-align: top;
}

.comments-list .commen-item .avatar img {
    height: 80px;
    width: 80px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    padding: 3px;
    border: 1px solid #e7e7e7;
    margin-top: -5px;
}

.comments-list .commen-item {
    margin-bottom: 30px;
}

.comments-list .commen-item.reply {
    padding-left: 80px;
}

.comments-area {
    margin-top: 40px;
}

.comments-area .comments-title h2,
.comments-area .comments-title h3,
.comments-area .comments-title h4 {
    border-bottom: 1px solid #e2e2e2;
    font-weight: 700;
    margin-bottom: 30px;
    padding-bottom: 15px;
    text-transform: uppercase;
}

.comments-list .commen-item .content h3,
.comments-list .commen-item .content h4,
.comments-list .commen-item .content h5 {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

.comments-info a {
    border: 1px solid #e7e7e7;
    color: #0cb8b6;
    display: inline-block;
    font-size: 12px;
    margin-top: 15px;
    padding: 1px 10px;
    text-transform: uppercase;
}

.comments-info a:hover {
    color: #0cb8b6;
}

.color-yellow .comments-info a:hover {
    color: #ff9800;
}

.comments-info a i {
    margin-right: 10px;
}

.comments-form input,
.comments-form textarea {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: #e7e7e7;
    border-image: none;
    border-radius: inherit;
    border-style: none none solid;
    border-width: medium medium 1px;
    box-shadow: inherit;
}

.comments-form textarea {
    min-height: 180px;
    padding: 15px;
}

.blog-area.full-blog.single-blog .form-group.submit {
    margin-bottom: 0;
}

.comments-form button {
    border: 2px solid #0cb8b6;
    background: transparent;
    color: #232323;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 25px;
    margin-top: 20px;
    padding: 12px 40px;
    text-transform: uppercase;
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
}

.comments-form button:hover {
    color: #ffffff;
    background-color: #0cb8b6;
    border: 2px solid transparent;
}

.comments-area .commen-item .comments-info {
    margin-bottom: 15px;
}

.comments-form .title h2,
.comments-form .title h3,
.comments-form .title h4 {
    border-bottom: 1px solid #e7e7e7;
    font-weight: 700;
    margin-bottom: 30px;
    padding-bottom: 15px;
    text-transform: uppercase;
}

.blog-area .contact-comments .comments {
    margin-top: 20px;
}

.blog-area.single .blog-items .item .contact-comments .col-md-6 {
    padding: 0 15px;
}

/* ==============================================================
     # Newsletter
=================================================================== */
.newsletter-area form {
    max-width: 400px;
    margin: auto;
    position: relative;
    z-index: 1;
}

.newsletter-area form .input-group {
    width: 100%;
}

.newsletter-area form input {
    border: none;
    -webkit-border-radius: 30px !important;
    -moz-border-radius: 30px !important;
    border-radius: 30px !important;
    padding: 0 20px;
    min-height: 60px;
}

.newsletter-area form button {
    position: absolute;
    right: 0;
    z-index: 9;
    min-height: 60px;
    border: 5px solid #ffffff;
    width: 60px;
    background: #0cb8b6;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    color: #ffffff;
}

.newsletter-area h2 {
    margin-bottom: 30px;
}

/* ==============================================================
     # Contact
=================================================================== */
.contact-area .contact-items input,
.contact-area .contact-items textarea {
    border: 1px solid #e7e7e7;
    box-shadow: inherit;
    min-height: 60px;
    padding: 15px;
}

.contact-area .contact-items textarea {
    min-height: 150px;
}

.contact-area .contact-items button {
    padding: 15px 35px;
    border: 2px solid #0cb8b6;
    background: transparent;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-top: 20px;
}

.contact-area .contact-items button i {
    margin-left: 5px;
}

.contact-area .contact-items img {
    float: left;
    margin-left: 10px;
    top: 40px;
    position: absolute;
}

/* Google Maps */

.maps-area .google-maps {
    width: 100%;
}

.google-maps iframe {
    height: 500px;
    margin-bottom: -10px;
    width: 100%;
}

.maps-area {
    overflow: hidden;
}

/* ==============================================================
     # Error Page
=================================================================== */

.error-box {
    margin-top: -5px;
}

.error-box h1 {
    font-size: 150px;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 30px;
}

.error-box h1 i {
    color: #0cb8b6;
}

.error-box h2 {
    text-transform: capitalize;
    font-weight: 600;
}

.error-box .search {
    border-top: 1px solid #e7e7e7;
    margin-top: 30px;
    padding-top: 30px;
    text-align: center;
}

.error-box .search form {
    width: 400px;
    margin: auto;
    position: relative;
    z-index: 1;
}

.error-box .search .input-group {
    margin: auto;
}

.error-box .search form input {
    box-shadow: inherit;
    border-radius: inherit;
    min-height: 60px;
}

.error-box .search form button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    min-height: 60px;
    background: #0cb8b6;
    border: none;
    padding: 0 30px;
    color: #ffffff;
}


/* ==============================================================
     # Footer
=================================================================== */

footer .f-items img {
    margin-bottom: 30px;
}

footer .f-item h2 {
    font-weight: 800;
    color: #0cb8b6;
    border: 1px solid;
    display: inline-block;
    padding: 10px 20px;
    margin: 0;
}

footer .f-item h2 i {
    margin-right: 5px;
}

footer .f-item h4 {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    position: relative;
    z-index: 1;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

footer .f-item h4::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    height: 2px;
    width: 30px;
    background: #0cb8b6;
}

footer .f-item h4::before {
    position: absolute;
    left: 33px;
    bottom: 0;
    content: "";
    height: 2px;
    width: 5px;
    background: #0cb8b6;
}

footer.bg-dark p,
footer.bg-dark {
    color: #cccccc;
}

footer .f-item h5 {
    text-transform: uppercase;
    margin-top: 25px;
    margin-bottom: 20px;
}


/* Footer Top */

footer .footer-top .item-box {
    border: 1px solid #e7e7e7;
    overflow: hidden;
    text-align: center;
}

footer .footer-top .item {
    border-right: 1px solid #e7e7e7;
    overflow: hidden;
    padding: 15px;
}

footer .footer-top .item:last-child {
    border: medium none;
}

footer .footer-top .item span {
    display: block;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

footer .footer-top .item i {
    display: inline-block;
    font-size: 50px;
    margin-bottom: 15px;
    color: #0cb8b6;
}

footer .f-item .opening-info li {
    display: block;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 8px;
    margin-bottom: 9px;
}

footer.bg-dark .f-item .opening-info li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

footer .f-item .opening-info li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}

footer .f-item .opening-info li .closed {
    background: #0cb8b6;
    color: #ffffff;
    padding: 2px 15px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

footer .f-item.link li {
    display: block;
    margin-bottom: 18px;
}

footer .f-item li a {
    display: inline-block;
    color: #666666;
}

footer.bg-dark .f-item li a {
    color: #cccccc;
}

footer .f-item.link li a i {
    margin-right: 6px;
    font-size: 10px;
}

footer .f-item li a:hover {
    color: #0cb8b6;
}

footer.bg-dark .f-item li a:hover {
    color: #ffffff;
}

footer .f-item.link li:last-child {
    margin: 0;
    padding: 0;
    border: none;
}

footer .f-items {
    float: left;
    width: 100%;
    overflow: hidden;
}

footer.active-first .f-items .item:first-child .f-item {
    position: relative;
    padding: 0 30px;
    z-index: 1;
}

footer.active-first .f-items .item:first-child .f-item a,
footer.active-first .f-items .item:first-child .f-item p,
footer.active-first .f-items .item:first-child .f-item li,
footer.active-first .f-items .item:first-child .f-item h5 {
    color: #ffffff;
}

footer.active-first .f-items .item:first-child .f-item::after {
    position: absolute;
    left: 0;
    top: -25%;
    content: "";
    height: 150%;
    width: 100%;
    background: #0cb8b6;
    z-index: -1;
    margin-top: -4px;
}

footer.active-first .f-items .item:first-child .f-item h4 {
    color: #ffffff;
}

footer.active-first .f-items .item:first-child .f-item h4::after,
footer.active-first .f-items .item:first-child .f-item h4::before {
    background: #ffffff;
}

footer.active-first .f-items .item:first-child .f-item .opening-info li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

footer.active-first .f-items .item:first-child .f-item .opening-info li:last-child {
    border: none;
}

footer.active-first .f-items .item:first-child .f-item .opening-info li .closed {
    background: #ffffff;
    color: #232323;
}

/* Recent Post */

footer .f-item.recent-post .meta-title,
footer .f-item.recent-post .meta-title a {
    color: #666666;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    text-transform: uppercase !important;
}

footer.bg-dark .f-item.recent-post .meta-title,
footer.bg-dark .f-item.recent-post .meta-title a {
    color: #ffffff;
}

footer.bg-dark .f-item.recent-post .meta-title a {
    color: #0cb8b6;
}

footer .f-item.recent-post .meta-title {
    margin-bottom: 5px;
    text-transform: uppercase;
}

footer .f-item.recent-post li a span {
    display: inline-block;
    color: #cdd0d3;
}

footer .f-item.recent-post li span {
    display: inline-block;
}

footer .f-item.recent-post li span i {
    margin-right: 5px;
}

footer .f-item.recent-post .meta-title a {
    text-transform: capitalize;
}

footer .f-item.recent-post li {
    color: #cdd0d3;
}

footer .f-item.recent-post li {
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 15px;
    padding-bottom: 20px;
}

footer.bg-dark .f-item.recent-post li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

footer .f-item.recent-post li:last-child {
    border: medium none;
    margin: 0;
    padding: 0;
}

footer .f-item.recent-post li .thumb {
    display: table-cell;
    vertical-align: top;
    width: 80px;
}

footer .f-item .thumb img {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 0;
}

footer .f-item.recent-post li .info {
    display: table-cell;
    padding-left: 20px;
    vertical-align: top;
    line-height: 26px;
    color: #837f7e;
}

footer .equal-height {
    padding-right: 30px;
}

footer .equal-height:last-child {
    padding-right: 15px;
}

footer .f-item .item {
    display: flex;
}

footer .f-item .item i {
    font-size: 30px;
    color: #0cb8b6;
}

footer .f-item .item .info {
    padding-left: 20px;
}

footer .f-item .item .info h3 {
}

footer .f-item .item .info h5 {
    margin-top: 0;
    margin-bottom: 15px;
}

footer .f-item.emergency li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 20px;
    padding-bottom: 20px;
}

footer .f-item.emergency li p {
    margin: 0;
}

footer .f-item.emergency li:last-child {
    margin: 0;
    padding: 0;
    border: none;
}

footer .footer-bottom li {
    display: inline-block;
}

footer .footer-bottom {
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

footer .footer-bottom p {
    margin: 0;
}

footer .footer-bottom.bg-light p {
    color: #666666;
}

footer .footer-bottom p a {
    color: #0cb8b6;
}

footer .footer-bottom .text-right li {
    margin-left: 20px;
}

footer .footer-bottom.bg-light li a {
    color: #232323;
}

footer .footer-bottom li a:hover {
    color: #0cb8b6;
}

footer .f-item.contact li i, footer .f-item.contact li p {
    display: table-cell;
    vertical-align: top;
}

footer .f-item.contact li p {
    margin: 0;
    color: #232323;
    padding-left: 15px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
}

footer.bg-dark .f-item.contact li p {
    color: #ffffff;
}

footer .f-item.contact li i {
    font-size: 25px;
    padding-top: 5px;
    color: #0cb8b6;
}

footer .f-item.contact li p span {
    display: block;
    font-family: 'Open Sans', sans-serif;
    color: #666666;
    text-transform: none;
}

footer.bg-dark .f-item.contact li p span {
    color: #cccccc;
}

footer .f-item.contact li p a {
    font-family: 'Open Sans', sans-serif;
    color: #666666;
}

footer.bg-dark .f-item.contact li p a {
    color: #cccccc;
}

footer .f-item.contact li {
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 15px;
}

footer .f-item.contact li:last-child {
    margin: 0;
    padding: 0;
    border: none;
}

footer form {
    width: 100%;
    position: relative;
    z-index: 1;
}

footer form .input-group {
    width: 100%;
}

footer .f-item.contact form input {
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
    box-shadow: inherit;
}

footer form button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    background: #0cb8b6;
    color: #ffffff;
    z-index: 9;
    border: none;
    line-height: 50px;
    min-width: 50px;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}

footer .f-item.twitter-widget .twitter-contentx {
    color: #0cb8b6;
}

footer .f-item .twitter-context i {
    color: #0cb8b6;
}

footer .f-item .twitter-item {
    margin-bottom: 30px;
}

footer .f-item .twitter-item:last-child {
    margin: 0;
}

footer .footer-bottom .social li a {
    color: #0cb8b6;
}

footer .footer-bottom .social li a:hover {
    color: #ffffff;
}


/* ==============================================================
     # Preloader
=================================================================== */
.no-js #loader {
    display: none;
}

.js #loader {
    display: block;
    position: absolute;
    left: 100px;
    top: 0;
}

.se-pre-con {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: url(../assets/img/preloader_out.gif) center no-repeat #fff;
    text-align: center;
}


.isDesktop {
    display: inherit !important;
}

.isMobile {
    display: none !important;
}

@media (max-width: 1024px) {
    .isDesktop {
        display: none !important;
    }

    .isMobile {
        display: inherit !important;
    }
}

.d-flex {
    display: flex;
}


del {
    text-decoration: line-through;
    color: #9b9b9b;
    font-size: 60%;
    white-space: nowrap;
}


.position-relative {
    position: relative !important
}

.d-block {
    display: block !important
}

.overflow-hidden {
    overflow: hidden !important
}

.rounded {
    border-radius: 10px !important;
}

.price .item ul {
    text-align: left;
    padding-left: 2rem;
    padding-bottom: 2rem;
}
.price .item li {
    display: block;
    position: relative;
    z-index: 1;
    padding-left: 25px;
    margin-bottom: 10px;
}

.price .item li::after {
    position: absolute;
    left: 0;
    content: "\f058";
    color: #086766;
    height: 10px;
    width: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
}

.price .item li:last-child {
    margin-bottom: 0;
}


.blog-area .item ul {
    text-align: left;
    padding-left: 2rem;
    padding-bottom: 2rem;
}
.blog-area .item li {
    display: block;
    position: relative;
    z-index: 1;
    padding-left: 15px;
    margin-bottom: 10px;
}

.blog-area .item li::after {
    position: absolute;
    left: -10px;
    content: "\f058";
    color: #0cb8b6;
    height: 10px;
    top: 0;
    width: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
}

.blog-area .item li:last-child {
    margin-bottom: 0;
}



